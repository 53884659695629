import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />

    <div className="four-oh-four">
      <div className="not-found-message">
        <h1>Page not found </h1>
        <Link to="/" className="not-found-link">
          Terug naar de homepage
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
